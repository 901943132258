.IC {
  max-width: 420px;
  margin: 20px;
}
/* IC - component="img" */
.IC > :nth-child(1) {
  height: 280px;
  border: none;
}
/* IC - Typography.gutterBottom */
.IC > :nth-child(2) > :nth-child(1) {
  font-family: "Poppins";
  font-weight: bold;
  color: white;
}
/* IC - Typography.variant="body2" */
.IC > :nth-child(2) > :nth-child(2) {
  font-family: "Poppins";
  color: #ddd;
  font-size: 0.8rem;
}
