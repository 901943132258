.HeaderRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Nunito";
}
/* HeaderRoot - AppBar */
.HeaderRoot > :nth-child(1) {
  background: none;
}
/* HeaderRoot - Toolbar */
.HeaderRoot > :nth-child(1) > :nth-child(1) {
  width: 80%;
  margin: 0 auto;
}
/* HeaderRoot - h1(Tessa.) */
.HeaderRoot > :nth-child(1) > :nth-child(1) > :nth-child(1) {
  flex-grow: 1;
  font-size: 1.5rem;
}
/* HeaderRoot - h1(Tessa.).Color */
.HeaderRoot > :nth-child(1) > :nth-child(1) > :nth-child(1) > span,
.Tessa {
  color: #5aff3d;
}
/* HeaderRoot - SortRoundedIcon */
.HeaderRoot > :nth-child(1) > :nth-child(1) > :nth-child(2) > :nth-child(1) {
  color: white;
  font-size: 1.7rem;
  transition: all 0.4s ease-in-out;
}
.HeaderRoot
  > :nth-child(1)
  > :nth-child(1)
  > :nth-child(2)
  > :nth-child(1):hover {
  color: #5aff3d;
}
/* HeaderRoot - Welcome */
.HeaderRoot > :nth-child(2) > :nth-child(1) {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
}
