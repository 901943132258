.Root {
  background-image: url("./assets/pexels-o.jpg");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 1536px) {
}
@media (min-width: 1200px) and (max-width: 1536px) {
}
@media (min-width: 990px) and (max-width: 1200px) {
}
@media (min-width: 768px) and (max-width: 990px) {
}
@media (min-width: 560px) and (max-width: 768px) {
  .Root {
    margin: 1.5vh;
    min-height: 97vh;
  }
  .PlaceToVisit {
    flex-direction: column;
  }
}
@media (max-width: 560px) {
  .Root {
    margin: 1vh;
    min-height: 98vh;
  }
  .PlaceToVisit {
    flex-direction: column;
  }
}
